import {useEffect, useRef, useState} from "react";
import {classNames} from "../utils/componentUtils";
import {RiCloseLine, RiPrinterLine, RiPushpinFill, RiRefreshLine} from "react-icons/ri";
import {MONEY_BUTTON_DATA} from "../constants/component_constants";
import {ExclamationCircleIcon} from "@heroicons/react/16/solid";
import TicketCard from "./card/TicketCard";
import NumberKeyPad from "./NumberKeyPad";
import neverLandGameData from "../utils/NeverLandGameData";
import neverLandUtils from "../utils/NeverLandUtils";
import {SEND_PRINT_INFO_API} from "../constants/api_constants";
import {ModalHandler, OpenModalHandle} from "./modal/ModalHandler";
import {accountInfo, SET_MONEY_BUTTON_LIST} from "../constants/constants";
import SelectedGameTable from "./table/SelectedGameTable";
import {getFetcherEB} from "../utils/fetcher_eb";


const customKeyPadInstance = (setInputPosition, setShowKeyPad) => {
  const events = {
    _onKeyPadValueChanged: null
  };
  const showKeyPad = (onKeyPadValueChanged, ref) => {
    events._onKeyPadValueChanged = onKeyPadValueChanged;
    const rect = ref.getBoundingClientRect();
    setInputPosition({
      top: rect.bottom + window.scrollY - 390, // 인풋의 상단 위치
      left: rect.left + window.scrollX,  // 인풋의 좌측 위치
    });
    setShowKeyPad(true);
  }
  const hideKeyPad = () => {
    events._onKeyPadValueChanged = null;
    setShowKeyPad(false);
  }
  const onInputValueChanged = (value) => {
    if (events._onKeyPadValueChanged) {
      events._onKeyPadValueChanged(value);
      return true;
    }
    return false;
  };
  return {showKeyPad, hideKeyPad, onInputValueChanged};
};

const CartSidebar = ({gameCardRef}) => {
  const [amountOnChange, setAmountOnChange] = useState(false);
  const [batchPrice, setBatchPrice] = useState(0);
  const [showKeyPad, setShowKeyPad] = useState(false);
  const [inputPosition, setInputPosition] = useState({top: 0, left: 0});
  const [refreshDate, setRefreshDate] = useState(new Date());
  const [__customKeyPadInstance, set__customKeyPadInstance] = useState(null);
  const [showMoneyButton, setShowMoneyButton] = useState([true, true, true, true, true, true, true, true]);
  const [showSelectTable, setShowSelectTable] = useState(false);
  const currentTime = new Date();
  const OpenModal = OpenModalHandle.create();

  useEffect(() => {
    // 로컬스토리지에 있는 버튼 표시여부 받아오기
    const _list = neverLandUtils.commons.load(SET_MONEY_BUTTON_LIST);
    if (_list) setShowMoneyButton(_list);
  }, [])

  const [isPoweredEventObject, setIsPoweredEventObject] = useState({
    // 우선순위 부여
    isPoweredRequest: false,
    disablePowered: () => {
      isPoweredEventObject.isPoweredRequest = false;
    },
  });

  const handleOnChanged = () => {
    setRefreshDate(new Date());
  };

  useEffect(() => {
    set__customKeyPadInstance(customKeyPadInstance(setInputPosition, setShowKeyPad));
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
    }
  }, []);

  let selected_ticket_data = null;
  const selectTicketDataList = (list) => {
    selected_ticket_data = list;
  }

  // <-- 인풋 & 키패드 핸들러 -->
  const sidebarInputRef = useRef('sidebar');

  const handleSideBarInputFocus = (e, ref) => {
    // setActiveInputRef(sidebarInputRef.current);
    e.target.value = batchPrice;
    setAmountOnChange(true);
    // 커서 제일 끝으로 보내기
    setTimeout(() => {
      const length = e.target.value.length;
      e.target.setSelectionRange(length, length);
    }, 0);
    // 키패드 인풋 아래에 띄우기
    if (ref) {
      const rect = ref.getBoundingClientRect();
      setInputPosition({
        top: rect.bottom + window.scrollY, // 인풋의 하단 위치
        left: rect.left + window.scrollX,  // 인풋의 좌측 위치
      });
      setShowKeyPad(true);
    }
  };

  const handleInputBlur = (e) => {
    // 키패드를 누른 경우, blur 이벤트 취소
    if (e.relatedTarget && e.relatedTarget.closest('.keypad')) {
      return;
    }
    e.target.value = batchPrice.toLocaleString();
    setAmountOnChange(false);
    setShowKeyPad(false);
  };

  const handleInputValueOnChange = (value) => {
    if (__customKeyPadInstance.onInputValueChanged(value)) {
      return;
    }
    let newValue;
    let temp = batchPrice.toString();
    if (value === 'delete') {
      if (temp.length === 1) {
        newValue = 0;
      } else if (temp.length > 1) {
        newValue = neverLandUtils.utils.textToNumber(temp.substring(0, temp.length - 1));
      }
    } else {
      newValue = neverLandUtils.utils.textToNumber(temp + value);
    }

    isPoweredEventObject.isPoweredRequest = true;
    setBatchPrice(newValue);
  };


  // <-- 인쇄하기 -->
  function printTickets(ticketData) {
    if (!ticketData.length) {
      OpenModal.openWarningModal('경기 없음', '경기를 선택 해주세요.');
      return;
    }
    for (let t of ticketData) {
      // 금액이 없는 티켓이 있으면 출력 불가
      if (t.selectGame.length > 10) {
        OpenModal.openWarningModal('출력 불가', '10 폴더이상이 포함된 경우 출력이 불가능합니다.');
        return;
      }
      // 금액이 없는 티켓이 있으면 출력 불가
      if (t.currentAmount === 0) {
        OpenModal.openWarningModal('금액 없음', '금액을 입력해 주세요.');
        return;
      }
      // 배팅금액 10만원 초과 출력 불가
      if (t.currentAmount > 100000) {
        OpenModal.openWarningModal('금액 초과', '배팅금액은 10만 원을 초과할 수 없습니다.');
        return;
      }
      // 단폴더 1000원 미만 출력 불가
      if (t.selectGame.length === 1 && t.currentAmount < 1000) {
        OpenModal.openWarningModal('금액 부족', '단폴더는 배팅금액 1,000원부터 가능합니다.');
        return;
      }
    }
    if (window.chrome && window.chrome.webview && window.chrome.webview.postMessage) {
      let print_data = [];
      let currentData;
      for (let currentTicket of ticketData) {
        currentData = {
          order_id: -1,
          purchase_amt: currentTicket.currentAmount,
          //2025-01-16 설치 프로그램에서 배당율 출력 시 필요한 배당률 데이터 추가 (memo변수에 배당률 값 넣기)
          memo: '배당율: ' + currentTicket.totalRate + "배 / ",
          username: '-',
          limit_allot: currentTicket.selectGame.length,
          visit_date: '-',
          matches: []
        };

        for (let game of currentTicket.selectGame) {
          currentData.matches.push({
            match_seq: getMatchSeqFromAllotId(game.allotId),
            bet_type: game.prediction === '승' ? 'win' : (game.prediction === '패' ? 'lose' : 'draw'),
            home_name: game.homeTeam.split(' ')[0].trim()
          });
        }
        print_data.push(currentData);
      }
      let msg = JSON.stringify({data: print_data, command: 'request_print'});
      window.chrome.webview.postMessage(msg);

    }
    sendPrintInfo(ticketData);
    setBatchPrice(0);
    onClear();
  }

  function getMatchSeqFromAllotId(allotId) {
    return parseInt(allotId.substring(allotId.lastIndexOf('_') + 1), 10);
  }

  let currentFolderNumber = neverLandGameData.currentFolderNumber();
  let currentFolderNumberGroup = neverLandGameData.currentFolderNumberGroup();

  const isToggleMode = accountInfo.getDeviceSetting()?.folder_toggle_mode;
  function _useToggleMode() {
    // 폴더 선택모드 불러와서 세팅
    if (!isToggleMode) return false;
    return isToggleMode;
  }

  function isActivatedFolders(folderNumber) {
    if (_useToggleMode()) {
      if (currentFolderNumberGroup) {
        if (currentFolderNumberGroup.length === 0) {
          return currentFolderNumber === folderNumber;
        }
        return currentFolderNumberGroup.includes(folderNumber);
      }
      return false;
    }
    return currentFolderNumber === folderNumber;
  }

  const sendPrintInfo = (ticket) => {
    // 출력할때 정보 백엔드 넘겨주기
    let _send_ticket_list_data = ticket.map(t => {
      const _truncatedAmount = Math.floor(t.currentAmount / 100) * 100;  // 개별 투표 금액 백단위 절삭 ex) 999원 -> 900원
      const _hitAmount = Math.round(_truncatedAmount * t.totalRate);  // 예상적중금 소수점 반올림 ex) 5,999.999원 -> 6,000원

      return {
        printDateTime: currentTime.toISOString(),
        printRoundNumber: Number(t.selectGame[0].allotId.split('_')[2]),
        itemPickNameEnum: '프로토',
        printMoney: _truncatedAmount,  // 개별투표 금액
        printTotalRate: t.totalRate,  // 예상 적중배당률
        printWinMoney: _hitAmount, // 예상 적중금
        storeCodeName: accountInfo.storeCode(), // 지점 코드
        machineCodeName: accountInfo.deviceCode(), // 기기 코드
        optimaDTOList: t.selectGame.map(g => ({
          optimaTypeEnum: g.prediction, // 예상 (승, 무, 패)
          optimaRateNumber: Number(g.rate), // 배당률
          allotId: g.allotId,
        }))
      };
    });

    getFetcherEB().post(SEND_PRINT_INFO_API, {printDTOList: _send_ticket_list_data})
        .then(res => {
          const {responseResultCode, responseResultDescription, storeList} = res.data;
          if (responseResultCode === "STORE_LIST_GET_SUCCESS") {
          } else {
            console.log(responseResultDescription);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .catch((err) => console.error(err));
  }

  const onClear = () => {
    // 경기 선택 초기화
    neverLandGameData.clearSelection();
    if (gameCardRef.current) {
      gameCardRef.current.scrollTop = 0; // 게임카드 스크롤 최상단으로
    }
    setBatchPrice(0);
  }

// function selectAllTickets() {
//   // 티켓 전체선택
//   let _selected_ticket_data = [];
//   for (let t of neverLandGameData.ticketData) {
//     _selected_ticket_data.push({
//       amount: t.currentAmount,
//       selectedGame: t.selectGame,
//       id: t.id
//     });
//   }
//   selected_ticket_data = _selected_ticket_data;
// }


  return (
      <div className="w-[22.5rem] h-full flex flex-col">
        <ModalHandler initModalHandle={OpenModal}/>

        <div className="w-full h-[3rem] flex flex-row justify-between items-center px-2">
          <button className="button-style bg-gray-600 mr-4 px-3 w-1/4"
                  onClick={() => {
                    window.location.reload();
                  }}>
            새로고침
          </button>

          <button className="button-style bg-lime-300 text-black w-3/4 flex flex-row justify-center items-center"
                  onClick={onClear}
          >
            <RiRefreshLine className="w-5 h-auto mr-2"/>
            경기 선택 초기화
          </button>
        </div>

        <div
            className="bg-gray-100 h-[calc(100%-3rem)] flex-grow py-5 px-2 my-3 mx-1 rounded-2xl shadow-md border border-gray-200 flex flex-col">
          {/* <-- 금액 입력 키패드 --> */}
          {showKeyPad &&
              <div className="absolute" style={{top: inputPosition.top, left: inputPosition.left - 200}}
                   onMouseDown={(e) => {
                     e.preventDefault(); // 키패드를 클릭해도 인풋의 blur 발생을 막음
                   }}
              >
                <NumberKeyPad handleInputValueOnChange={handleInputValueOnChange} handleInputBlur={handleInputBlur}/>
              </div>
          }

          {/* <-- 폴더 버튼 ~ 출력 버튼 --> */}
          <div className="flex flex-col justify-start">
            {/* <-- 폴더 버튼 --> */}
            <div className="flex flex-row justify-start mb-3 flex-wrap">
              {neverLandGameData.folderList().map((folderNumber) => {


                return (
                    <button
                        type="button"
                        key={folderNumber}
                        className={classNames(isActivatedFolders(folderNumber) ? "bg-gray-900 text-white ring-gray-900" : "bg-white text-gray-900 ring-slate-300",
                            "mb-2 px-3 py-2 mr-2 rounded text-sm font-bold shadow-md ring-1 ring-inset"
                        )}
                        onClick={() => {
                          isPoweredEventObject.isPoweredRequest = true;
                          if (_useToggleMode()) {
                            neverLandGameData.toggleFolderNumberGroup(folderNumber)
                            return;
                          }
                          neverLandGameData.refreshFolder(folderNumber);
                        }}
                    >
                      {folderNumber}폴더
                    </button>
                )
              })}
            </div>

            {/* <-- 경기 고정 버튼 --> */}
            <div className="flex justify-center px-5 mb-4">
              <button
                  onClick={() => setShowSelectTable(prevState => !prevState)}
                  type="button"
                  className="button-style bg-amber-200 text-black w-full py-1 flex flex-row justify-center items-center"
              >
                <RiPushpinFill className="w-5 h-auto text-red-600 mr-2"/>
                선택 경기 고정
              </button>
            </div>
            {/* <-- 경기 고정 테이블 --> */}
            {showSelectTable && <SelectedGameTable/>}

            {/* <-- 금액 입력 --> */}
            <div className="flex flex-row justify-between items-center px-5">
              <p className="text-md sm:text-lg font-semibold whitespace-nowrap">금액 일괄입력</p>
              <div
                  className="flex flex-row rounded-md bg-white border-0 ring-1 ring-inset ring-gray-300 py-0.5 pl-3 mx-2">
                <input
                    className="text-left w-24 py-1 font-semibold sm:leading-6 focus:ring-0 focus:ring-white mr-2 caret-white"
                    ref={sidebarInputRef} // Sidebar 인풋에 대한 ref
                    value={(amountOnChange ? batchPrice : batchPrice.toLocaleString())}
                    onFocus={(e) => handleSideBarInputFocus(e, sidebarInputRef.current)}
                    onBlur={handleInputBlur}
                    onChange={handleInputValueOnChange}
                />
                <button type="reset">
                  <RiCloseLine className="h-4 w-auto px-1"
                               onClick={() => {
                                 isPoweredEventObject.isPoweredRequest = true;
                                 setBatchPrice(0);
                               }}/>
                </button>
              </div>
              <p className="text-lg font-semibold">원</p>
            </div>

            {/* <-- 금액 버튼 --> */}
            <div className="flex flex-wrap justify-between mt-2 px-5">
              {MONEY_BUTTON_DATA.map((data, index) =>
                      showMoneyButton?.[index] && ( // 지점별 리스트 받아와서 인덱스값이 true 일때만 띄우기
                          <button
                              className="rounded bg-white w-[4.2rem] py-2 mt-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-slate-300 whitespace-nowrap"
                              key={data.value}
                              onClick={() => {
                                isPoweredEventObject.isPoweredRequest = true;
                                setBatchPrice((prevPrice) => Number(prevPrice + data.value));
                              }}
                              value={data.value}
                              type="button"
                          >
                            {data.name}
                          </button>
                      )
              )}
            </div>

            {/* <-- 출력 버튼 --> */}
            <div className="flex justify-center px-5">
              <button
                  onClick={() => {
                    // selectAllTickets();
                    printTickets(neverLandGameData.ticketData);
                  }}
                  type="button"
                  className="button-style bg-blue-600 text-white w-full py-2 mt-5 flex flex-row justify-center items-center"
              >
                <RiPrinterLine className="w-5 h-auto text-white mr-2"/>
                출력하기
              </button>
            </div>
          </div>

          {neverLandGameData.ticketData.length > 0 &&
              <p className="text-center font-bold mt-2 h-6 w-full bg-gray-100">
                총 {neverLandGameData.ticketData.length}개의 조합
              </p>
          }
          {/* 티켓카드영역 */}
          <div className="w-full flex-grow overflow-y-auto flex flex-col mt-2">
            {neverLandGameData._selectedItems.length > 0 ? (
                neverLandGameData.ticketData.length === 0 ? (
                    <div className="flex flex-col justify-center mt-5 items-center">
                      <ExclamationCircleIcon className="h-10 w-10 mr-5"/>
                      <p className="font-semibold text-lg">
                        ※ 잘못된 조합입니다. <br/>
                        아래와 같은 상황인지 확인해주세요. <br/><br/>
                        1. 싱글이외의 경기를 하나만 선택 <br/>
                        2. 같은 리그내의 경기만 선택 <br/>
                        3. 같은 리그내의 경기를 여러개 고정 <br/>
                      </p>
                    </div>
                ) : (
                    <TicketCard batchPrice={batchPrice}
                                isPoweredObject={isPoweredEventObject}
                                selectTicketDataList={selectTicketDataList}
                                customKeyPadInstance={__customKeyPadInstance}
                    />
                )
            ) : (
                <div className="flex flex-col justify-center mt-5 items-center">
                  <ExclamationCircleIcon className="h-10 w-10 mr-5 my-3"/>
                  <p className="font-semibold text-lg">
                    선택된 경기가 없습니다. <br/>
                    경기를 선택해 주세요.
                  </p>
                </div>
            )
            }
          </div>
        </div>
      </div>
  );
}
export default CartSidebar;