import neverLandUtils from "../utils/NeverLandUtils";
import neverLandGameData from "../utils/NeverLandGameData";
import {useState} from "react";
import {useLocalStorage} from "../utils/useLocalStorage";

export const USER_INFO = 'ui*'
export const USER_CONFIG = 'uc'


export const STORE_CODE = 'eb_sc'
export const DEVICE_CODE = 'eb_dc'
export const SET_MONEY_BUTTON_LIST = 'eb_mb'
export const DEVICE_SETTING = 'd_set'
export const DEVICE_SETTING_DEFAULT_LIST = {dark_mode: true, folder_toggle_mode: false};


let _thisConfig = null;

export function _getResourcePath(_url) {
  let currentUrl = window.location.href;

  if (currentUrl.includes('.com')) {
    const newUrl = new URL(_url);
    newUrl.hostname = currentUrl.replace('http://', '').replace('https://', '').split("/")[0]; // 도메인 부분만 대체

    _url = newUrl.href;
  }
  return _url;
}

class MyAccountInfo {
  //로컬스토리지 저장되어있는 정보 가져오기
  isLogin() {
    return neverLandUtils.commons.load(USER_INFO)?.userId;
  }

  storeCode() { // 로그인 했을 때 받은 지점 코드
    return neverLandUtils.commons.load(STORE_CODE) || -1;
  }

  deviceCode() {  // 로그인 했을 때 받은 기기 코드
    return neverLandUtils.commons.load(DEVICE_CODE) || -1;
  }

  getDeviceSetting() {  // 기기 설정 정보 가져오기
    return neverLandUtils.commons.load(DEVICE_SETTING) || -1;
  }

  isDarkMode() {  // 화면 다크모드 여부
    const isDarkMode = this.getDeviceSetting() ? this.getDeviceSetting().dark_mode : true;

    // 다크 모드 여부에 따라 css 클래스 추가
    if (isDarkMode !== null && !isDarkMode) {
      document.body.classList.add('light-mode');
    } else {
      document.body.classList.remove('light-mode');
    }

    if (isDarkMode === null) return true; // 값이 없으면 다크모드
    return isDarkMode;
  }
}

const {REACT_APP_SERVER_IMAGE_FOLDER} = process.env;

const image_resource_urls = {
  // 경기장
  'soccer_ground': REACT_APP_SERVER_IMAGE_FOLDER + "/soccer_play_ground.img",
  // 티켓 배경 이미지
  'ticketNormal': "http://teamneverland.com/resources/img/ticket/ticket_normal_02.png",
  'ticketWin': "http://teamneverland.com/resources/img/ticket/ticket_win.png",
  'ticketLose': "http://teamneverland.com/resources/img/ticket/ticket_lose.png",
  'ticketExpired': "http://teamneverland.com/resources/img/ticket/ticket_expired.png",
  'ticketOrange': "http://teamneverland.com/resources/img/ticket/ticket_normal_orange.png",
};

export function getImage(shape) {
  let currentUrl = window.location.href;
  let _url = image_resource_urls[shape];

  if (currentUrl.includes('.com')) {
    const newUrl = new URL(_url);
    newUrl.hostname = currentUrl.replace('http://', '').replace('https://', '').split("/")[0]; // 도메인 부분만 대체

    _url = newUrl.href;
  }

  if (_url !== undefined) {
    return _url;
  }
}

export const today = new Date();
export const month = today.getMonth();
export const day = today.getDate();
export const yesterday = new Date(today);
yesterday.setDate(today.getDate() - 1);

export const lastWeek = new Date(today);
lastWeek.setDate(today.getDate() - 7);

export const lastMonth = new Date(today);
lastMonth.setMonth(today.getMonth() - 1);

export const MOBYDICK_VERSION_URL = "/resources/current/version.txt";

export const accountInfo = new MyAccountInfo();
